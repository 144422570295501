@charset "UTF-8";

// application des couleurs selon l'endroit du template ou on le pose
// Ex : @include site-map-color($white,$blue);
@mixin block--slideshow-color($color: $dark, $bgColor: $white, $colorHover: $white) {
  .block--slideshow{
    .type-slick-slider {
      // link
      .slide-wrapper {
        .caption {
          color: $color;
          .push a{
            &:not(:hover):not(:focus){
              color: $color;
            }
          }
        } 
      }
      // arrows
      .slider-box {
        .slick-arrow:not(:hover):not(:focus) {
          span {
            color: $color;
          } 
        } 
      }
    }
    // dots
    .slick-dots {
      li {
        &.slick-active button .slide-dots:not(:hover):not(:focus) {
          background: $color !important;    
        }
      }  
    }
    
    .type-slick-slider {
      background-color: $bgColor;
    }
    .slide-wrapper {
      background-color: $bgColor;
      color: $color;
    }

    .no-touch &,
    .no-touchevents &{
      .type-slick-slider {
        // link
        .slide-wrapper {
          .caption {
            .push a{
              &:hover,
              &:focus{
                color: $colorHover;
              }
            }
          } 
        }
        // arrows
        .slider-box {
          .slick-arrow {
            &:hover,
            &:focus{
              span {
                color: $colorHover;
              } 
            }
          } 
        }
      }
      // dots
      .slick-dots {
        li {
          button .slide-dots {
            &:hover,
            &:focus{
              background: $colorHover !important;    
            }
          }
        }  
      }
    }
  
  }
}


// application des couleurs selon l'endroit du template ou on le pose
// Ex : @include block--slidehome-color($colors);
@mixin block--slideshow-color--bgcolor($code, $bgColor: $grey-block) {
  .block--slideshow.bgcolor-#{$code}{
    .type-slick-slider {
      background-color: $bgColor;
    }
    .slide-wrapper {
      background-color: $bgColor;
    }
  }
}

@mixin block--slideshow-color--color($code, $color: $dark, $colorHover: $white) {
  .block--slideshow.color-#{$code}{
    .type-slick-slider {
      // link
      .slide-wrapper {
        .caption {
          color: $color;
          .push a{
            &:not(:hover):not(:focus){
              color: $color;
            }
          }
        } 
      }
      // arrows
      .slider-box {
        .slick-arrow:not(:hover):not(:focus) {
          span {
            color: $color;
          } 
        } 
      }
    }
    // dots
    .slick-dots {
      li {
        &.slick-active button .slide-dots:not(:hover):not(:focus) {
          background: $color !important;    
        }
      }  
    }


    .no-touch &,
    .no-touchevents &{
      .type-slick-slider {
        // link
        .slide-wrapper {
          .caption {
            .push a{
              &:hover,
              &:focus{
                color: $colorHover;
              }
            }
          } 
        }
        // arrows
        .slider-box {
          .slick-arrow {
            &:hover,
            &:focus{
              span {
                color: $colorHover;
              } 
            }
          } 
        }
      }
      // dots
      .slick-dots {
        li {
          button .slide-dots {
            &:hover,
            &:focus{
              background: $colorHover !important;    
            }
          }
        }  
      }
    }
  
  }
}