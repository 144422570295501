@charset "UTF-8";

// application des couleurs selon l'endroit du template ou on le pose
// Ex : @include block--slidehome-color($colors);
@mixin block--slidehome-color($color: $dark, $bgColor: $grey-block) {
  .block--slidehome{
    .type-slick-slider {
      .slide-wrapper {
        .caption {
          strong {
            color: $color;
          }
        } 
      }
      .slider-box {
        .slick-arrow:not(:hover):not(:focus) {
          span {
            color: $color;
          } 
        } 
      }
    }
    .slick-dots {
      li {
        &.slick-active button .slide-dots:not(:hover):not(:focus) {
          background: $color !important;    
        }
      }  
    }
    
    .slide-wrapper {
      .caption {
        strong {
          background-color: rgba($bgColor, 0.8);
        }
      }
    }

  }
}

// application des couleurs selon l'endroit du template ou on le pose
// Ex : @include block--slidehome-color($colors);
@mixin block--slidehome-color--bgcolor($code, $bgColor: $grey-block) {
  .block--slidehome.bgcolor-#{$code}{
    .slide-wrapper {
      .caption {
        strong {
          //background-color: rgba($bgColor, 0.8);
          background-color: $bgColor;
        }
      }
    }
  }
}

@mixin block--slidehome-color--color($code, $color: $dark, $colorHover: $white) {
  .block--slidehome.color-#{$code}{
    .type-slick-slider {
      .slide-wrapper {
        .caption {
          strong {
            color: $color;
          }
        } 
      }
      .slider-box {
        .slick-arrow:not(:hover):not(:focus) {
          span {
            color: $color;
          } 
        } 
      }
    }
    .slick-dots {
      li {
        &.slick-active button .slide-dots:not(:hover):not(:focus) {
          background: $color !important;    
        }
      }  
    }
    

    .no-touch &,
    .no-touchevents &{
      .type-slick-slider {
        .slider-box {
          .slick-arrow{
            &:hover,
            &:focus{
              span {
                color: $colorHover;
              } 
            }
          } 
        }
      }
      .slick-dots {
        li {
          button .slide-dots {
            &:hover,
            &:focus{
              background: $colorHover !important;    
            }
          }
        }  
      }
    }
  }
}