@charset "UTF-8";

// application des couleurs selon l'endroit du template ou on le pose
// Ex : @include menu-privateaccess-color($white,$blue,$dark,$dark);
@mixin menu-privateaccess-color($color: $white, $hoverColor: $blue, $fa_color: $white, $fa_hoverColor: $blue) {
  .menu-privateaccess{
    a{
      color: $color;
      .fa{
        color: $fa_color;
      }
    }
    .no-touch &,
    .no-touchevents &{
      a[href],
      a[tabindex]{
        &:hover,
        &:focus{
          color: $hoverColor;
          .fa{
            color: $fa_hoverColor;
          }
        }
      }
    }
  }
}