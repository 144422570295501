@charset "UTF-8";

// application des couleurs selon l'endroit du template ou on le pose
// Ex : @include menu-inline-color($white,$blue);
@mixin menu-inline-color($color: $white, $hoverColor: $blue) {
  .menu-inline{
    li{
      &:not(:last-child){
        &:after{
          background: $color;
        }
      }
    }
    a{
      color: $color;
    }
    .no-touch &,
    .no-touchevents &{
      a[href],
      a[tabindex]{
        &:hover,
        &:focus{
          color: $hoverColor;
        }
      }
    }
  }
}