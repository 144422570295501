@charset "UTF-8";

// application des couleurs selon l'endroit du template ou on le pose
// Ex : @include block--editorial-color($grey-light);
@mixin block--editorial-color($color: $grey-light, $bgColor: $grey-block, $colorHover: $blue-dark) {
  .block--editorial{

    .editorial {
      background: $bgColor;
      color: $color;
      @include push-color($color, $colorHover);
      a {
        color: $color;
        &:visited {
          color: $color;
        }
        &:hover {
          color: $colorHover;
        }
      }
    }
  }
}

// application des couleurs selon l'endroit du template ou on le pose
// Ex : @include block--download-color($colors);
@mixin block--editorial-color--bgcolor($code, $bgColor: $grey-block) {
  .block--editorial.bgcolor-#{$code}{
    .editorial {
      background: $bgColor;
    }
  }
}

@mixin block--editorial-color--color($code, $color: $dark, $colorHover: $white) {
  .block--editorial.color-#{$code}{
    .editorial {
      color: $color;
      @include push-color--color($code, $color, $colorHover);
      a {
        color: $color;
        &:visited {
          color: $color;
        }
        &:hover {
          color: $colorHover;
        }
      }
    }
  }
}
