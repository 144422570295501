@charset "UTF-8";

// application des couleurs selon l'endroit du template ou on le pose
// Ex : @include faq-question-color($colors);

@mixin faq-question-color($color: $dark, $bgColor: $grey-block, $buttonBgColor: $dark) {
  
  .faq {

    .search {
      color: $color;  
    }

    .faq-list {
  
      .faq-item {

        background: $bgColor;  
        color: $color;
        
        .question {
          .cssarrow{
            &:after {
              border-top-color: $buttonBgColor;
            }
          }
        }
      }
    }
  }
}

@mixin faq-question-color--color($code, $color: $dark) {
  .faq {
    .faq-list {
      .faq-item {
        .question {
          .cssarrow.color-#{$code}{
            &:after {
              border-top-color: $color;
            }
          }
        }
      }
    }
  }
}