@charset "UTF-8";

// application des couleurs selon l'endroit du template ou on le pose
// Ex : @include menu-sitemap-color($white,$blue);
@mixin menu-sitemap2-color($color: $white, $hoverColor: $blue, $activeColor: $blue-light) {
  .menu-sitemap2{
    a{
      color: $color;
    }
    // puces
    > ul > li > a:before {
      background-color: $color;
    }
    > ul > li > ul > li a:before {
      color: $color;
    }

    .no-touch &,
    .no-touchevents &{
      a[href],
      a[tabindex]{
        &:hover,
        &:focus{
          color: $hoverColor;
        }
      }
      > ul > li > a[href],
      > ul > li > a[tabindex] {
        &:hover,
        &:focus{
          &:before{
            background-color: $hoverColor;
          }
        }
      }
      > ul > li > ul > li a[href],
      > ul > li > ul > li a[tabindex] {
        &:hover,
        &:focus{
          &:before{
            color: $hoverColor;
          }
        }
      }
    }
    a.active{
      color: $activeColor;
    }
    > ul > li > a.active:before {
      background-color: $activeColor;
    }
    > ul > li > ul > li a.active:before {
      color: $activeColor;
    }
  }
}