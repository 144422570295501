@charset "UTF-8";

// application des couleurs selon l'endroit du template ou on le pose
// Ex : @include block-header-color($white,$blue);
@mixin block-header-color($color: $white, $bgColor: $blue) {
  .block-header{
    background: $bgColor;
    color: $color;
    .subtitle{
      &:before{
        background: $color;
      }
    }
  }
}