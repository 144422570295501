@charset "UTF-8";


// application des couleurs selon l'endroit du template ou on le pose
// Ex : @include form-submit-color($color-input-border, $blue);
@mixin button-color($color: $white, $bgColor: $blue-dark, $colorHover: $white, $bgColorHover: $blue) {
  .button{
    background: $bgColor;
    color: $color;

    .no-touch &,
    .no-touchevents &{
      &:hover,
      &:focus{
        background: $bgColorHover;
        color: $colorHover;
      }
    }
  }
}

@mixin button-font($font: $fontfamily) {
  .button{
    font-family: $font, $fontfamily;
  }
}