@charset "UTF-8";

// application des couleurs selon l'endroit du template ou on le pose
// Ex : @include form-input-color($color-input-border, $blue);
@mixin form-input-color($borderColor: $color-input-border, $borderColorActive: $blue, $color: $dark) {
  #page,
  .popin{
    input[type=text]:not(.gwt-TextBox):not(.org-opencms-gwt-client-ui-css-I_CmsInputCss-textBox),
    input[type=email]:not(.gwt-TextBox):not(.org-opencms-gwt-client-ui-css-I_CmsInputCss-textBox),
    input[type=password]:not(.gwt-TextBox):not(.org-opencms-gwt-client-ui-css-I_CmsInputCss-textBox),
    input[type=number]:not(.gwt-TextBox):not(.org-opencms-gwt-client-ui-css-I_CmsInputCss-textBox),
    select:not(.gwt-TextBox):not(.org-opencms-gwt-client-ui-css-I_CmsInputCss-textBox),
    textarea:not(.gwt-TextBox):not(.org-opencms-gwt-client-ui-css-I_CmsInputCss-textBox){
      border: 1px solid $borderColor;
      color: $color;

      // &:invalid,   // because match if field empty
      &.invalid,
      .webform_field_error &{
        border: 1px solid red;
      }

      .no-touch &,
      .no-touchevents &{
        &:hover,
        &:focus{
          border: 1px solid $borderColorActive;
        }
      }
    }
  }

  .field,
  .webform_row{
    .error,
    .webform_label_error{
      color: red;
    }
  }
  .webform_msg_error{
    color: red;
  }
}

// application des couleurs selon l'endroit du template ou on le pose
// Ex : @include form-submit-color($color-input-border, $blue);
@mixin form-submit-color($color: $white, $bgColor: $blue-dark, $colorHover: $white, $bgColorHover: $blue) {
  #page,
  .popin{
    input[type=submit],
    input[type=reset]{
      background: $bgColor;
      color: $color;

      .no-touch &,
      .no-touchevents &{
        &:hover,
        &:focus{
          background: $bgColorHover;
          color: $colorHover;
        }
      }
    }
  }
}

@mixin form-font($font: $fontfamily) {
  #page,
  .popin{
    input[type=text],
    input[type=email],
    input[type=password],
    input[type=number],
    input[type=submit],
    input[type=reset],
    select,
    textarea,
    button{
      font-family: $font, $fontfamily;
    }
  }
}