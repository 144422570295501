@charset "UTF-8";

// application des couleurs selon l'endroit du template ou on le pose
// Ex : @include site-map-color($white,$blue);
@mixin block--sitemap-color($color: $dark, $hoverColor: $blue, $activeColor: $blue-light, $bgColor: transparent) {
  .block--sitemap{
    
    nav{
      background: $bgColor;  
      color: $color;  
    }

    @include menu-sitemap2-color($color, $hoverColor, $activeColor);

  }
}