@charset "UTF-8";

// Ex : @include menu-inline-color($white,$blue);
@mixin resize-buttons-color($color: $white, $hoverColor: $blue) {
  .resize-buttons{
    color: $color;
    .fas:hover,
    .fas:focus{
      color: $hoverColor;
    }
  }
}

@mixin resize-buttons-color--hovercolor($code, $hoverColor: $grey-block) {
  .resize-buttons.hovercolor-#{$code}{
	.fas:hover,
    .fas:focus{
      color: $hoverColor;
    }
  }
}

@mixin resize-buttons-color--color($code, $color: $dark) {
  .resize-buttons.color-#{$code}{
      color: $color;
  }
}