@charset "UTF-8";

// application des couleurs selon l'endroit du template ou on le pose
// Ex : @include numberCard-color($blue,$white);
@mixin numberCard-color($borderColor: $blue, $bgColor: $white, $color: $dark) {
  .numberCard{
    border: 2px solid $borderColor;
    background: $bgColor;
    color: $color;
  }
}