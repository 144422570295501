@charset "UTF-8";



// fonts par defaut

$fontfamily: Open sans, Arial, sans serif;



// widths & grids

$desktop-max-width-header: 1280px;
$desktop-max-width: 980px;
$stretch-max-width: calc( 100% - 20px );

// breakpoints
$desktop-header-start-width: 1280px;
$desktop-classic-start-width: 980px;
$desktop-start-width: 750px;
$tablet-end-width: 749px;
$tablet-start-width: 560px;
$mobile-end-width: 559px;




$mediaquery-desktop: 'only screen and (min-width: #{$desktop-start-width})';
$mediaquery-desktop-centered: 'only screen and (min-width: #{$desktop-classic-start-width})';
$mediaquery-desktop-large: 'only screen and (min-width: #{$desktop-header-start-width})';
$mediaquery-tablet-and-up: 'only screen and (min-width: #{$tablet-start-width})';
$mediaquery-tablet-and-down: 'only screen and (max-width: #{$tablet-end-width})';
$mediaquery-mobile-only: 'only screen and (max-width: #{$mobile-end-width})';

$fa-icons: (
  'chevron-right',
  'hand-point-right',
  'piggy-bank',
  'money-bill-alt',
  'shipping-fast',
  'fighter-jet',
  'balance-scale',
  'chart-bar',
  'percentage',
  'landmark',
  'file-invoice',
  'coins',
  'calendar-alt',
  'link',
  'arrow-circle-down',
  'download',
  'tasks',
  'th-list'
);
