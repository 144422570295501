@charset "UTF-8";

// application des couleurs selon l'endroit du template ou on le pose
// Ex : @include menu-social-links-color($white,$blue,$dark,$dark);
@mixin menu-social-links-color($color: $white, $hoverColor: $blue, $fa_color: $white, $fa_hoverColor: $blue) {
  .menu-social-links{
    a{
      // par defaut pas de fond
      color: $color;
      .fa,.fab{
        color: $color;
      }
      // fond carre (obsolete)
      &.squared{
        background: $color;
        .fa,.fab{
          color: $fa_color;
        }
      }
      // option 2023 : fond rounded
      &.rounded{
        background: $color;
        .fa,.fab{
          color: $fa_color;
        }
      }
    }
    .no-touch &,
    .no-touchevents &{
      a[href],
      a[tabindex]{
        &:hover,
        &:focus{
          color: $hoverColor;
          .fa,.fab{
            color: $hoverColor;
          }
        }
        &.squared{
          &:hover,
          &:focus{
            background: $hoverColor;
            .fa,.fab{
              color: $fa_hoverColor;
            }
          }
        }
        &.rounded{
          &:hover,
          &:focus{
            background: $hoverColor;
            .fa,.fab{
              color: $fa_hoverColor;
            }
          }
        }
      }
    }
  }
}
