@charset "UTF-8";

// application des couleurs selon l'endroit du template ou on le pose
// Ex : @include menu-locales-color($white,$blue);
@mixin menu-locales-color($color: $dark, $hoverColor: $blue, $currentColor: $dark, $hoverCurrentColor: $blue) {
  .menu-locales{
    a{
      color: $color;
    }
    a.current-locale{
      color: $currentColor;
    }

    .no-touch &,
    .no-touchevents &{
      a[href],
      a[tabindex]{
        &:hover,
        &:focus{
          color: $hoverColor;
        }
      }
      a.current-locale{
        &:hover,
        &:focus{
          color: $hoverCurrentColor;
        }
      }
    }
  }
}