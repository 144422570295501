@charset "UTF-8";

@import '_variables';

@import 'framework/menu-inline_colors';
@import 'framework/menu-social-links_colors';
@import 'framework/menu-sitemap_colors';
@import 'framework/menu-locales_colors';
@import 'framework/menu-privateaccess_colors';
@import 'framework/menu-main_colors';
@import 'framework/menu-sitemap2_colors';
@import 'framework/forms_colors';
@import 'framework/popin_colors';
@import 'framework/pushs_colors';
@import 'framework/numberCard_colors';
@import 'framework/buttons_colors';
@import 'framework/faq_colors';
@import 'framework/resize-buttons_colors';

@import 'blocks/block-login_colors';
@import 'blocks/block-teaser_colors';
@import 'blocks/block-group_colors';
@import 'blocks/block-sitemap_colors';
@import 'blocks/block-download_colors';
@import 'blocks/block-advantages_colors';
@import 'blocks/block-contactform_colors';
@import 'blocks/block-faq_colors';
@import 'blocks/block-editorial_colors';
@import 'blocks/block-simulation_colors';
@import 'blocks/block-slideshow_colors';
@import 'blocks/block-cookies_colors';
@import 'blocks/block-header_colors';
@import 'blocks/block-slidehome_colors';
@import 'blocks/block-backtotop_colors';

@import 'pages/template_colors';

// ----------------------------------------------------------------
// !!!!!!!!!!!!!!!!!! CAREFULL !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// Keys must have to be absolutely unique and NOT NESTED
// These keys must be reported in the java replacer functions
// ----------------------------------------------------------------

$white: #ffffff;
$dark: #263039;

// KEYS -----------------------------------------------------------

// FONT : custom font-family name

// FOOTERBGCOLOR
// FOOTERCOLORDEFAULT ($white)
// FOOTERCOLORHOVER ($blue, LINKCOLORHOVER)
// FOOTERCOLORACTIVE ($blue-light)

// HEADERCOLORDEFAULT (HEADERCOLOR)
// HEADERCOLORHOVER
// HEADERCOLORACTIVE

// // MENUMAINDESKTOPLEVEL2COLORDEFAULT (HEADERCOLORDEFAULT)
// MENUMAINDESKTOPLEVEL2COLORHOVER (HEADERCOLORHOVER2)
// // MENUMAINDESKTOPLEVEL2COLORACTIVE (HEADERCOLORACTIVE)
// MENUMAINDESKTOPLEVEL2BGCOLORHOVER (HEADERBGCOLORHOVER2)

// MENUMAINMOBILECOLORDEFAULT (MENUMAINMOBILECOLOR)
// MENUMAINMOBILECOLORHOVER
// MENUMAINMOBILECOLORACTIVE
// MENUMAINMOBILEBGCOLOR

// // MENUMAINMOBILELEVEL2COLORDEFAULT (MENUMAINMOBILECOLORDEFAULT)
// MENUMAINMOBILELEVEL2COLORHOVER (MENUMAINMOBILECOLORHOVER2)
// // MENUMAINMOBILELEVEL2COLORACTIVE (MENUMAINMOBILECOLORACTIVE)
// MENUMAINMOBILELEVEL2BGCOLORHOVER (MENUMAINMOBILEBGCOLORHOVER2)

// // SYSTEMCOLORDEFAULT ($dark)
// // LINKCOLORDEFAULT ($dark)
// LINKCOLORHOVER
// LINKCOLORACTIVE
// TITLECOLOR - TITLEBGCOLOR

// SYSTEMFORMCOLORDEFAULT (LOGINBLOCKCOLOR)
// SYSTEMFORMBGCOLOR (LOGINBLOCKBGCOLOR)
// SYSTEMFORMINPUTBORDERCOLORDEFAULT (FORMINPUTBORDERCOLOR)
// SYSTEMFORMINPUTBORDERCOLORACTIVE (FORMINPUTBORDERCOLORACTIVE)
// SYSTEMFORMSUBMITCOLORDEFAULT (FORMSUBMITCOLOR)
// SYSTEMFORMSUBMITCOLORHOVER (FORMSUBMITCOLORHOVER)
// SYSTEMFORMSUBMITBGCOLORDEFAULT (FORMSUBMITBGCOLOR)
// SYSTEMFORMSUBMITBGCOLORHOVER (FORMSUBMITBGCOLORHOVER)

// COOKIESBGCOLOR
// COOKIESBGBUTTONCOLOR (deprecated)
// COOKIESCOLOR


@include template-font(FONT);
@include form-font(FONT);
@include button-font(FONT);




@include template-color(FOOTERBGCOLOR, FOOTERCOLOR, HEADERCOLORDEFAULT, MENUMAINMOBILEBGCOLOR);
@include menu-inline-color(FOOTERCOLOR, LINKCOLORHOVER);
@include menu-social-links-color(FOOTERCOLOR, LINKCOLORHOVER,FOOTERBGCOLOR,FOOTERBGCOLOR);
@include menu-sitemap-color(FOOTERCOLOR, LINKCOLORHOVER, LINKCOLORHOVER);
@include menu-sitemap2-color($dark, LINKCOLORHOVER, LINKCOLORHOVER);
@include menu-locales-color(HEADERCOLORDEFAULT, HEADERCOLORHOVER, HEADERCOLORDEFAULT, HEADERCOLORHOVER);
@include menu-privateaccess-color(HEADERCOLORDEFAULT, HEADERCOLORHOVER, HEADERCOLORDEFAULT, HEADERCOLORHOVER);
@include menu-main-color--mobile(MENUMAINMOBILECOLORDEFAULT, MENUMAINMOBILECOLORHOVER, MENUMAINMOBILECOLORACTIVE, MENUMAINMOBILELEVEL2COLORHOVER, MENUMAINMOBILELEVEL2BGCOLORHOVER, MENUMAINMOBILEBGCOLOR);
@include menu-main-color--desktop(HEADERCOLORDEFAULT, HEADERCOLORHOVER, HEADERCOLORACTIVE, MENUMAINDESKTOPLEVEL2COLORHOVER, MENUMAINDESKTOPLEVEL2BGCOLORHOVER);
@include burger-btn-color(MENUMAINMOBILECOLORDEFAULT, MENUMAINMOBILECOLORHOVER, MENUMAINMOBILECOLORACTIVE);

@include form-input-color(SYSTEMFORMINPUTBORDERCOLORDEFAULT, SYSTEMFORMINPUTBORDERCOLORACTIVE, $dark);
@include form-submit-color(SYSTEMFORMSUBMITCOLORDEFAULT, SYSTEMFORMSUBMITBGCOLORDEFAULT, SYSTEMFORMSUBMITCOLORDEFAULT, SYSTEMFORMSUBMITBGCOLORHOVER);
@include button-color(SYSTEMFORMSUBMITCOLORDEFAULT, SYSTEMFORMSUBMITBGCOLORDEFAULT, SYSTEMFORMSUBMITCOLORDEFAULT, SYSTEMFORMSUBMITBGCOLORHOVER);
@include popin-color(transparent,SYSTEMFORMBGCOLOR,LINKCOLORHOVER);
@include numberCard-color(SYSTEMFORMINPUTBORDERCOLORDEFAULT,SYSTEMFORMBGCOLOR,SYSTEMFORMCOLORDEFAULT);
@include block--cookies-color(COOKIESCOLOR,COOKIESBGCOLOR,SYSTEMFORMSUBMITCOLORDEFAULT, SYSTEMFORMSUBMITBGCOLORDEFAULT, SYSTEMFORMSUBMITCOLORDEFAULT, SYSTEMFORMSUBMITBGCOLORHOVER);
@include resize-buttons-color($dark,LINKCOLORHOVER);
@include block-header-color(TITLECOLOR, TITLEBGCOLOR);

@include block--login-form-color(SYSTEMFORMCOLORDEFAULT, SYSTEMFORMBGCOLOR, LINKCOLORHOVER);
@include popin--login-form-color(SYSTEMFORMCOLORDEFAULT, transparent, LINKCOLORHOVER);
// @include block--teaser-color(#fff, grey, red);
// @include block--group-color(grey);
// @include block--download-color(#ffffff);
@include block--contact-form-color(SYSTEMFORMCOLORDEFAULT, LINKCOLORHOVER, SYSTEMFORMBGCOLOR);
@include block--sitemap-color($dark, LINKCOLORHOVER, LINKCOLORACTIVE, transparent);
@include block--slidehome-color(TITLECOLOR, $dark);
@include block--editorial-color(TITLECOLOR, $dark, LINKCOLORHOVER);
@include block--simulation-color(SYSTEMFORMCOLORDEFAULT, SYSTEMFORMBGCOLOR);
@include block--slideshow-color(TITLECOLOR, $dark,LINKCOLORHOVER);
@include block--backtotop-color--bgcolor(BACKTOTOPBACKGROUNDCOLOR);
@include block--download-color($dark,$white,LINKCOLORHOVER);
@include block--advantages-color(SYSTEMFORMCOLORDEFAULT,SYSTEMFORMBGCOLOR,LINKCOLORHOVER);
@include block--teaser-color($dark,$white,LINKCOLORHOVER,BACKTOTOPBACKGROUNDCOLOR);
@include faq-question-color(SYSTEMFORMCOLORDEFAULT, SYSTEMFORMBGCOLOR, SYSTEMFORMSUBMITBGCOLORDEFAULT);

// Colors variants
$variants: (
  (000000,#000000,#000000),
  (3E505D,#3E505D,#2b3841),
  (929292,#929292,#666666),
  (263039,#263039,#1a2127),
  (db0011,#db0011,#99000b),
  (AF000D,#AF000D,#7a0009),
  (83000A,#83000A,#5b0007),
  (ffffff,#ffffff,#b2b2b2),
  (727378,#727378,#4f5054),
  (dadbdb,#dadbdb,#989999),
  (C10CODE,C10HEXADEFAULT,C10HEXADARKEN)
);

@each $color in $variants {
  $label: nth($color,1);
  $hexa: nth($color,2);
  $hexadarken: nth($color,3);

  @include block--teaser-color--bgcolor($label,$hexa,$hexadarken);
  @include block--teaser-color--color($label,$hexa,LINKCOLORHOVER);
  @include block--teaser-color--bordercolor($label, $hexa);
  @include block--group-color--bgcolor($label,$hexa);
  @include block--download-color--bgcolor($label,$hexa);
  @include block--download-color--color($label,$hexa,LINKCOLORHOVER);
  @include block--advantages-color--bgcolor($label,$hexa);
  @include block--advantages-color--color($label,$hexa,LINKCOLORHOVER);
  @include block--editorial-color--color($label,$hexa,LINKCOLORHOVER);
  @include block--editorial-color--bgcolor($label,$hexa);
  @include block--slideshow-color--bgcolor($label, $hexa);
  @include block--slideshow-color--color($label, $hexa, LINKCOLORHOVER);
  @include block--slidehome-color--bgcolor($label, $hexa);
  @include block--slidehome-color--color($label, $hexa, LINKCOLORHOVER);
  @include resize-buttons-color--hovercolor($label,$hexa);
  @include resize-buttons-color--color($label,$hexa);
  @include faq-question-color--color($label,$hexa);
}

// transparent
@include block--teaser-color--bgcolor('transparent',transparent,transparent);
@include block--teaser-color--bordercolor('transparent',transparent);
@include block--group-color--bgcolor('transparent',transparent);
@include block--download-color--bgcolor('transparent',transparent);
@include block--advantages-color--bgcolor('transparent',transparent);
@include block--editorial-color--bgcolor('transparent',transparent);
@include block--slideshow-color--bgcolor('transparent',transparent);
@include block--slidehome-color--bgcolor('transparent',transparent);
