@charset "UTF-8";

// application des couleurs selon l'endroit du template ou on le pose
// Ex : @include block--simulation-color($colors);
@mixin block--simulation-color($color: $dark, $bgColor: $grey-block) {
  .block--simulation{
    
    .simulation{
      background: $bgColor;  
      color: $color;  
    }

  }
}