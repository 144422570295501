@charset "UTF-8";

// application des couleurs selon l'endroit du template ou on le pose
// Ex : @include template-color($white,$blue);
@mixin template-color($footerbgcolor: $dark, $footercolor: $white, $headerbordercolor: $dark, $mobiletoolbarbgcolor: $blue-dark) {
  body {
    background: $footerbgcolor;
  }
  #header{
    .menu-main{
      @media #{$mediaquery-desktop}{
        border-top: 1px solid $headerbordercolor;
      }
    }
    .mobile-toolbar{
      @media #{$mediaquery-tablet-and-down}{
        background: $mobiletoolbarbgcolor;
      }
    }
  }
  #footer{
    background: $footerbgcolor;
    color: $footercolor;
  }
}

@mixin template-font($font: $fontfamily) {
  html * {
    font-family: $font, $fontfamily;
  }
}