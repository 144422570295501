@charset "UTF-8";

// application des couleurs selon l'endroit du template ou on le pose
// Ex : @include block--teaser-color($colors);
@mixin block--teaser-color($color: $dark, $bgColor: $grey-block, $colorHover: $white, $bordercolor: $dark) {
  .block--teaser{
    
    // > a,
    // &.no-link{
    //   background: $bgColor;
    //   color: $color;
    //   transition: 0.3s;
    // }
    background: $bgColor;
    color: $color;
    transition: 0.3s;
    @include push-color($color, $colorHover);
    a{
      color: $color;
      &:visited {
        color: $color;
      }
      &:hover {
        color: $colorHover;
      }
    }

    // .no-touch &,
    // .no-touchevents &{
    //   > a[href],
    //   > a[tabindex]{
    //     &:hover,
    //     &:focus{
    //       background: $bgColorHover;
    //     }
    //   }
    // }

    // bordure bottom
    .content_container{
      border-color: $bordercolor;
    }
  }
}

// application des couleurs selon l'endroit du template ou on le pose
// Ex : @include block--teaser-color($colors);
@mixin block--teaser-color--bgcolor($code, $bgColor: $grey-block, $bgColorHover: $blue) {
  .block--teaser.bgcolor-#{$code}{
    
    // > a,
    // &.no-link{
    //   background: $bgColor;
    // }
    background: $bgColor;

    // .no-touch &,
    // .no-touchevents &{
    //   > a[href],
    //   > a[tabindex]{
    //     &:hover,
    //     &:focus{
    //       background: $bgColorHover;
    //     }
    //   }
    // }

  }
}
@mixin block--teaser-color--color($code, $color: $dark, $colorHover: $white) {
  .block--teaser.color-#{$code}{  
    color: $color;
    @include push-color--color($code, $color, $colorHover);
    a {
      color: $color;
      &:visited {
        color: $color;
      }
      &:hover {
        color: $colorHover;
      }
    }
  }
}
@mixin block--teaser-color--bordercolor($code, $color: $dark) {
  .block--teaser.bordercolor-#{$code}{  
    // bordure bottom
    .content_container{
      border-color: $color;
    }
  }
}