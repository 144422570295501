@charset "UTF-8";

// application des couleurs selon l'endroit du template ou on le pose
// Ex : @include block--group-color($grey-light);
@mixin block--contact-form-color($color: $dark, $hoverColor: $blue, $bgColor: $grey-block) {
  .block--contact-form{
    .contact{
      background: $bgColor;  
      color: $color;  
    }

    // field confirmation link natif Alkacon
    .webform_field{
      a{
        color: $color;
      }
    }
    .no-touch &,
    .no-touchevents &{
      .webform_field{
        a[href]{
          &:hover,
          &:focus{
            color: $hoverColor;
          }
        }
      }
    } 
  }
}