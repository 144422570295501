@charset "UTF-8";

// application des couleurs selon l'endroit du template ou on le pose
// Ex : @include popin-color($blue,$white);
@mixin popin-color($borderColor: $blue, $bgColor: $white, $colorHover: $blue) {
  .popin{

    &:not(#popin-video){
      border: 2px solid $borderColor;
      background: $bgColor;
    }

    // skin popin-video
    &#popin-video{
      border-color: transparent;
      background: transparent;
    }

    .no-touch &,
    .no-touchevents &{
      .popin-close{
        a[href],
        a[tabindex]{
          &:hover,
          &:focus{
            color: $colorHover;
          }
        }
      }
    }
  }
}