@charset "UTF-8";

// application des couleurs selon l'endroit du template ou on le pose
// Ex : @include block--cookies-color($colors);
@mixin block--cookies-color($color: $white, $bgColor: $blue-dark, $buttoncolor: $white, $buttonbgColor: $blue-dark, $buttoncolorHover: $white, $buttonbgColorHover: $blue) {
  .block--cookies{

    color: $color;
    
    .cookiepolicymessage {
      background: $bgColor;
    }
    
    .cookiepolicybuttons {
      button {
        color: $buttoncolor;
        background: $buttonbgColor;
        &.disagree{
          color: $color;
          background: transparent;
        }
      }
    }

    .no-touch &,
    .no-touchevents &{
      .cookiepolicybuttons {
        button {
          &:hover,
          &:focus{
            color: $buttoncolorHover;
            background: $buttonbgColorHover;
          }
          &.disagree{
            &:hover,
            &:focus{
              color: $color;
              background: transparent;
            }
          }
        }
      }
    }
  }
}