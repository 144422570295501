@charset "UTF-8";

// application des couleurs selon l'endroit du template ou on le pose
// Ex : @include block--login-form-color($colors);
@mixin block--login-form-color($color: $dark, $bgColor: $grey-block, $colorHover: $blue) {
  .block--login-form{
    
    .login,
    .request-password{
      background: $bgColor;  
      color: $color;  
    }

    
    .no-touch &,
    .no-touchevents &{
      .related{
        a{
          &:hover,
          &:focus{
            color: $colorHover;
          }
        }
      }
      
    }

  }
}

// application des couleurs selon l'endroit du template ou on le pose
// Ex : @include popin--login-form-color($colors);
@mixin popin--login-form-color($color: $dark, $bgColor: transparent, $colorHover: $blue) {
  #popin-login{
    
    .login,
    .request-password{
      background: $bgColor;  
      color: $color;  
    }

    
    .no-touch &,
    .no-touchevents &{
      .related{
        a{
          &:hover,
          &:focus{
            color: $colorHover;
          }
        }
      }
      
    }

  }
}