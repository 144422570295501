@charset "UTF-8";

// application des couleurs selon l'endroit du template ou on le pose
// Ex : @include block--group-color($grey-light);
@mixin block--advantages-color($color: $dark, $bgColor: $grey-light, $hoverColor: $blue-dark) {
  .block--advantages{
    background: $bgColor;
    color: $color;
    @include push-color($color, $hoverColor);
  }
}

// application des couleurs selon l'endroit du template ou on le pose
// Ex : @include block--download-color($colors);
@mixin block--advantages-color--bgcolor($code, $bgColor: $grey-block) {
  .block--advantages.bgcolor-#{$code}{
    background: $bgColor;
  }
}

@mixin block--advantages-color--color($code, $color: $dark, $hoverColor: $blue) {
  .block--advantages.color-#{$code}{
    color: $color;
    @include push-color--color($code, $color, $hoverColor);
  }
}