@charset "UTF-8";

// application des couleurs selon l'endroit du template ou on le pose
// Ex : @include block--group-color($grey-light);
@mixin block--group-color($bgColor: $grey-light) {
  .block--group{
    background: $bgColor;
  }
}

// application des couleurs selon l'endroit du template ou on le pose
// Ex : @include block--group-color($colors);
@mixin block--group-color--bgcolor($code, $bgColor: $grey-block) {
  .block--group.bgcolor-#{$code}{
    background: $bgColor;
  }
}