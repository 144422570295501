@charset "UTF-8";

// Ex : @include menu-inline-color($white,$blue);
@mixin push-color($color: $white, $hoverColor: $blue) {
  .push{
    color: $color;
    a{
      color: $color;
    }
    .no-touch &,
    .no-touchevents &{
      a[href],
      a[tabindex]{
        &:hover,
        &:focus{
          color: $hoverColor;
        }
      }
    }
  }
}

@mixin push-color--color($code, $color: $dark, $hoverColor: $blue) {
  & .push{
    color: $color;
    a{
      color: $color;
    }
  }
  .no-touch & .push,
  .no-touchevents & .push{
    a[href],
    a[tabindex]{
      &:hover,
      &:focus{
        color: $hoverColor;
      }
    }
  }
}