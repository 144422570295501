@charset "UTF-8";

// application des couleurs selon l'endroit du template ou on le pose
// Ex : @include menu-main-color($white,$blue);
@mixin menu-main-color--mobile($color: $dark, $hoverColor: $blue, $activeColor: $blue-light, $hover2Color: $blue, $hover2BgColor: transparent, $bgcolor: $blue-dark) {
  .menu-main{
    @media #{$mediaquery-tablet-and-down}{
      a{
        color: $color;
        &.active:not(.home){
          color: $activeColor;
        }
      }
      .no-touch &,
      .no-touchevents &{
        a[href],
        a[tabindex]{
          &:hover,
          &:focus{
            color: $hoverColor;
          }
        }
        ul{
          a[href],
          a[tabindex]{
            &:hover,
            &:focus{
              color: $hover2Color;
              background: $hover2BgColor;
            }
          }
        }
      }
      > ul{
        background: $bgcolor;
      }
    }
  }
}
@mixin menu-main-color--desktop($color: $dark, $hoverColor: $blue, $activeColor: $blue-light, $hover2Color: $blue, $hover2BgColor: transparent) {
  .menu-main{
    @media #{$mediaquery-desktop}{
      a{
        color: $color;
        &.active:not(.home){
          color: $activeColor;
        }
      }
      .no-touch &,
      .no-touchevents &{
        a[href],
        a[tabindex]{
          &:hover,
          &:focus{
            color: $hoverColor;
          }
        }
        ul ul{
          a[href],
          a[tabindex]{
            &:hover,
            &:focus{
              color: $hover2Color;
              background: $hover2BgColor;
            }
          }
        }
      }
    }
  }
}
@mixin burger-btn-color($color: $white, $hoverColor: $blue, $activeColor: $blue-light) {
  .burger-btn{
    color: $color;
    &.active{
      color: $activeColor;
    }
    .no-touch &,
    .no-touchevents &{
      &:hover,
      &:focus{
        color: $hoverColor;
      }
    }
  }
}